import { createStore } from 'vuex'

export default createStore({
  state: {
    verModal: true
  },
  getters: {
  },
  mutations: {
    setVermodal(state, val) {
      state.verModal= val
    }
  },
  actions: {
  },
  modules: {
  }
})
