import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/BasePrincipal.vue')
  },
  {
    path: '/quienes-somos',
    name: 'quienes_somos',
    component: () => import('../views/QuienesSomos.vue')
  },
  {
    path: '/seguros',
    name: 'seguros_apex',
    component: () => import('../views/CorretajeSeguros.vue')
  },
  {
    path: '/leasing',
    name: 'leasing_apex',
    component: () => import('../views/LeasingView.vue')
  },
  {
    path: '/servicio-financiero',
    name: 'servicio_financiero',
    component: () => import('../views/SerFinanciero.vue')
  },
  {
    path: '/inversiones',
    name: 'inversiones',
    component: () => import('../views/InversionesApex.vue')
  },
  { path: '/:pathMatch(.*)', name: 'bad-not-found', component: () => import('../views/BasePrincipal.vue')},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router