<template>
  <header>
   <div class="wrapper">
    <nav>
      <input type="checkbox" id="show-search">
      <input type="checkbox" id="show-menu">
      <label for="show-menu" class="menu-icon"><i class="fas fa-bars"></i></label>
      <div class="content">
        <div class="logo"><a href="/"><div class="logo_header_apex">
          <img src="../src/assets/img/header/logo.svg" alt="logo_apex" id="logo_header_apx" @click="scrollToTop">
        </div></a></div>
        <ul class="links">
          <li><router-link to="/" class="link_nav_menu" @click="scrollToTop">Inicio</router-link></li>
          <li>
            <a href="#" class="desktop-link link_nav_menu">Líneas de Negocio</a>
            <input type="checkbox" id="show-services">
            <label for="show-services" class="link_nav_menu">Líneas de Negocio</label>
            <ul>
              <li><router-link to="leasing" class="item_link" @click="scrollToTop">Leasing</router-link></li>
              <li><router-link to="seguros" class="item_link" @click="scrollToTop">Corretaje de Seguros</router-link></li>
              <li><router-link to="servicio-financiero" class="item_link" @click="scrollToTop">Servicios Financieros</router-link></li>
              <li><router-link to="inversiones" class="item_link" @click="scrollToTop">Inversiones</router-link></li>
            </ul>
          </li>
          <!-- <li>
            <a href="#" class="desktop-link link_nav_menu">Nosotros</a>
            <input type="checkbox" id="show-services">
            <label for="show-services" class="link_nav_menu">Nosotros</label>
            <ul>
              <li><a href="quienes-somos" class="item_link">¿Quiénes Somos?</a></li>
              <li><a href="#" class="item_link">Mejores Prácticas</a></li>
            </ul>
          </li> -->
          <li><a href="https://formulario.apex.gt/cotizador-apex" class="link_nav_menu">Cotizar</a></li>
          <li><a href="https://formulario.apex.gt/formulario-apex" class="link_nav_menu">Contáctanos</a></li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<router-view/>
<footer>
  <div class="cont_poweredby">
    <div class="contac_foot_container">
      <div class="footer_logo">
        <img src="../src/assets/img/footer/apx_logofooter.svg" alt="logo-footer">
      </div>
      <div class="contact_footer_cont">
        <div class="ofi_admin">
          <!-- <h4>Oficinas Administrativas</h4> -->
          <!-- <p>APEX S.A.</p> -->
          <p>Dirección: Diagonal 6, 12-42, zona 10</p>
          <p>Edificio Design Center, Torre 1, Oficina 801</p>
          <a href="mailto:servicioalcliente@apex.gt"><p>servicioalcliente@apex.gt</p></a>
          <a href="tel:+50223015100">PBX: +502 2301-5100</a>
        </div>
        <div class="ofi_operativ">
          <!-- <h4>Oficinas Operativas</h4>
          <p>46 Calle 18-11 zona 12 Ofibodegas</p>
          <p>Petapa II Bodega 6</p>
          <a href="tel:+50223015151">+502-2301-5151</a> -->
          <div class="cont_social_footer i_foot">
            <a href="https://www.facebook.com/ApexCapitalCentroamerica" target="blank"><i class="fa-brands fa-facebook"></i></a>
            <br>
            <a href="https://www.linkedin.com/company/apex-capital-centroam%C3%A9rica/mycompany/" target="blank"><i class="fa-brands fa-linkedin"></i></a>
            <br>
            <a href="https://www.instagram.com/apex_capital_centroamerica/" target="blank"><i class="fa-brands fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="powered_apex_container">
      <p class="powered_apex_group">Orgullosamente</p>
      <a href="nosotros.html"><img src="../src/assets/img/footer/gupoapexlogo.svg" alt="" class="grupo_apex_foot"></a>
    </div>
  </div>
</footer>
</template>

<style>
@import "assets/css/menu.css";
@import "assets/css/style.css";
</style>
<script>
export default {
  methods: {
    scrollToTop() {
      document.getElementById('show-menu').checked = false
        window.scrollTo(0, 0); // Desplaza la ventana al principio de la página
      }
    }
  }
  </script>